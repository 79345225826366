var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      [
        _vm.GetAdminValidations
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c("b-col", { attrs: { md: "12" } }, [
                  _c(
                    "h1",
                    {
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        ,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(235, "Validations")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-alert",
                      { attrs: { show: "", variant: "secondary" } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              this.FormMSG(
                                1,
                                "Only administrators are shown in this screen. You can add more administrators in the users screen."
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._l(_vm.GetAdminValidations, function (user) {
                      return _c(
                        "b-card",
                        {
                          key: user.id,
                          attrs: { header: _vm.userTitle(user) },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(user.validations, function (validation, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "col-12 col-sm-6" },
                                [
                                  _c(
                                    "b-card",
                                    { staticClass: "card-accent-primary" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                validation.departmentName
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-header-actions",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.deleteValidation(
                                                        validation.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "icon-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(40, "Timesheet"),
                                            "label-for": "ts",
                                            "label-cols": 5,
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              id: "ts",
                                              plain: true,
                                              options:
                                                _vm.optionsForValidationLevel,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.addOrUpdateValidation(
                                                  _vm.isUpdate,
                                                  validation.id,
                                                  parseInt(user.id),
                                                  validation.department,
                                                  validation.validationLevelTs,
                                                  validation.validationLevelPo,
                                                  validation.validationLevelExp
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                validation.validationLevelTs,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  validation,
                                                  "validationLevelTs",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "validation.validationLevelTs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              41,
                                              "Purchase Order"
                                            ),
                                            "label-for": "po",
                                            "label-cols": 5,
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              id: "po",
                                              plain: true,
                                              options:
                                                _vm.optionsForValidationLevel,
                                              value: "test",
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.addOrUpdateValidation(
                                                  _vm.isUpdate,
                                                  validation.id,
                                                  parseInt(user.id),
                                                  validation.department,
                                                  validation.validationLevelTs,
                                                  validation.validationLevelPo,
                                                  validation.validationLevelExp
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                validation.validationLevelPo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  validation,
                                                  "validationLevelPo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "validation.validationLevelPo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(42, "Expense"),
                                            "label-for": "exp",
                                            "label-cols": 5,
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              id: "exp",
                                              plain: true,
                                              options:
                                                _vm.optionsForValidationLevel,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.addOrUpdateValidation(
                                                  _vm.isUpdate,
                                                  validation.id,
                                                  parseInt(user.id),
                                                  validation.department,
                                                  validation.validationLevelTs,
                                                  validation.validationLevelPo,
                                                  validation.validationLevelExp
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                validation.validationLevelExp,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  validation,
                                                  "validationLevelExp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "validation.validationLevelExp",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("b-button", {
                                  attrs: {
                                    variant: "outline-success",
                                    block: "",
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.FormMSG(20, "Add Department")
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addDepartmentPrompt(
                                        user.id,
                                        user.name + " " + user.firstName
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-modal",
          {
            staticClass: "modal-success",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              "hide-footer": "",
              title: "Add validation to " + _vm.currentUserName + " for",
              "modal-class": "mui-animation",
              fade: false,
            },
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          },
          _vm._l(_vm.availableDepartments, function (department, i) {
            return _c("div", { key: i }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addDepartment(department)
                    },
                  },
                },
                [_vm._v(_vm._s(department.message))]
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }