<template>
	<errorContainer :error="erreur">
		<div>
			<div class="row" v-if="GetAdminValidations">
				<b-col md="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
						{{ FormMSG(235, 'Validations') }}
					</h1>
				</b-col>
				<div class="col-12">
					<b-alert show variant="secondary">
						{{ this.FormMSG(1, 'Only administrators are shown in this screen. You can add more administrators in the users screen.') }}
					</b-alert>
					<b-card v-for="user in GetAdminValidations" :header="userTitle(user)" :key="user.id">
						<!-- <div class="row"> -->
						<div class="row">
							<div class="col-12 col-sm-6" v-for="(validation, i) in user.validations" :key="i">
								<b-card class="card-accent-primary">
									<div slot="header">
										{{ validation.departmentName }}
										<div class="card-header-actions">
											<a href="#" v-on:click.prevent="deleteValidation(validation.id)"><i class="icon-close"></i></a>
										</div>
									</div>
									<b-form-group :label="FormMSG(40, 'Timesheet')" label-for="ts" :label-cols="5">
										<b-form-select
											@change="
												addOrUpdateValidation(
													isUpdate,
													validation.id,
													parseInt(user.id),
													validation.department,
													validation.validationLevelTs,
													validation.validationLevelPo,
													validation.validationLevelExp
												)
											"
											v-model="validation.validationLevelTs"
											id="ts"
											:plain="true"
											:options="optionsForValidationLevel"
										></b-form-select>
									</b-form-group>
									<b-form-group :label="FormMSG(41, 'Purchase Order')" label-for="po" :label-cols="5">
										<b-form-select
											@change="
												addOrUpdateValidation(
													isUpdate,
													validation.id,
													parseInt(user.id),
													validation.department,
													validation.validationLevelTs,
													validation.validationLevelPo,
													validation.validationLevelExp
												)
											"
											v-model="validation.validationLevelPo"
											id="po"
											:plain="true"
											:options="optionsForValidationLevel"
											value="test"
										></b-form-select>
									</b-form-group>
									<b-form-group :label="FormMSG(42, 'Expense')" label-for="exp" :label-cols="5">
										<b-form-select
											@change="
												addOrUpdateValidation(
													isUpdate,
													validation.id,
													parseInt(user.id),
													validation.department,
													validation.validationLevelTs,
													validation.validationLevelPo,
													validation.validationLevelExp
												)
											"
											v-model="validation.validationLevelExp"
											id="exp"
											:plain="true"
											:options="optionsForValidationLevel"
										></b-form-select>
									</b-form-group>
								</b-card>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<b-button
									variant="outline-success"
									block
									@click="addDepartmentPrompt(user.id, user.name + ' ' + user.firstName)"
									v-html="FormMSG(20, 'Add Department')"
								>
								</b-button>
							</div>
						</div>
					</b-card>
				</div>
			</div>
			<b-modal
				header-class="header-class-modal-doc-package"
				v-model="showModal"
				class="modal-success"
				hide-footer
				:title="'Add validation to ' + currentUserName + ' for'"
				modal-class="mui-animation"
				:fade="false"
			>
				<div v-for="(department, i) in availableDepartments" :key="i">
					<a href="#" v-on:click.prevent="addDepartment(department)">{{ department.message }}</a>
				</div>
			</b-modal>
		</div>
	</errorContainer>
</template>

<script>
import draggable from 'vuedraggable';
import gql from 'graphql-tag';
import languageMessages from '@/mixins/languageMessages';
import {
	getAdminValidations as Q_getAdminValidations,
	getDepartmentsForValidation as Q_getDepartmentsForValidation,
	AddUpdValidation as M_AddUpdValidation,
	DeleteValidation as M_DeleteValidation
} from '@/cruds/validations.crud';
import { store } from '@/store';
import { DEPARTEMENT_VALIDATION } from '@/utils/utils';

const userDisplay = {
	props: {
		department: Object
	},
	template: '<div><span class="movable">{{ department.message }} </span></div>'
};

let idGlobal = 1000;

export default {
	//name: "two-lists",
	name: 'Validations',
	mixins: [languageMessages],
	display: 'Two Lists',
	order: 1,
	components: {
		draggable,
		userDisplay
	},
	data() {
		const { isAdd, isUpdate } = DEPARTEMENT_VALIDATION;
		return {
			erreur: {},
			showModal: false,
			currentUserID: 0,
			currentUserName: '',
			GetAdminValidations: [],
			GetDepartmentsForValidation: [],
			isAdd,
			isUpdate
		};
	},
	apollo: {},
	async created() {
		await this.initialize();
	},
	methods: {
		async initialize() {
			let result_getAdminValidations = await Q_getAdminValidations();
			let result_getDepartmentsForValidation = await Q_getDepartmentsForValidation();
			result_getAdminValidations !== undefined && (this.GetAdminValidations = result_getAdminValidations);
			result_getDepartmentsForValidation !== undefined && (this.GetDepartmentsForValidation = result_getDepartmentsForValidation);
		},
		userTitle: function (user) {
			if (user.name.length > 0 || user.firstName.length > 0) {
				return user.name + ' ' + user.firstName;
			} else {
				return user.email;
			}
		},
		async deleteValidationGql(UserId, DepartmentNumber) {
			let result = await M_DeleteValidation({ UserId, DepartmentNumber, Type: 0 });
			return result;
		},
		addOrUpdateValidation: async function (action, ValidationId, UserId, DepartmentNumber, LevelTs, LevelPo, LevelExp, Replace) {
			if (DepartmentNumber === 0) {
				DepartmentNumber = -1;
			}
			await M_AddUpdValidation({
				ValidationId: action === this.isAdd ? 0 : ValidationId,
				UserId: UserId,
				ProjectId: +store.projectID(),
				UpdValidation: {
					type: 0,
					department: DepartmentNumber,
					validationLevelTs: LevelTs,
					validationLevelPo: LevelPo,
					validationLevelExp: LevelExp
				},
				Replace
			});
		},
		addDepartmentPrompt: function (userID, userName) {
			this.currentUserID = parseInt(userID);
			this.currentUserName = userName;
			this.showModal = true;
		},
		async addDepartment(department) {
			var validation = JSON.parse(JSON.stringify(department));
			validation.departmentName = validation.message;
			validation.department = validation.value;
			validation.validationLevelTs = 0;
			validation.validationLevelPo = 0;
			validation.validationLevelExp = 0;
			// Add validation to user
			if (validation.value === 0) {
				validation.department = -1;
			}
			await M_AddUpdValidation({
				ValidationId: 0,
				UserId: this.currentUserID,
				ProjectId: +store.projectID(),
				UpdValidation: {
					type: 0,
					department: validation.value,
					validationLevelTs: validation.validationLevelTs,
					validationLevelPo: validation.validationLevelPo,
					validationLevelExp: validation.validationLevelExp
				}
			}).then((res) => {
				// console.log(res);
				res.departmentName = validation.message;
				for (var i = 0; i < this.GetAdminValidations.length; i++) {
					if (this.GetAdminValidations[i].id == this.currentUserID) {
						this.GetAdminValidations[i].validations.push(res);
						break;
					}
				}
			});
			// liste

			// // graphql
			// this.addOrUpdateValidation(
			// 	this.isAdd,
			// 	0,
			// 	this.currentUserID,
			// 	validation.value,
			// 	validation.validationLevelTs,
			// 	validation.validationLevelPo,
			// 	validation.validationLevelExp
			// );

			// hide modal
			this.showModal = false;
			this.currentUserID = 0;
			this.currentUserName = '';
		},
		deleteValidation: function (id) {
			//console.log("delete validation:", id)
			for (var i = 0; i < this.GetAdminValidations.length; i++) {
				for (var j = 0; j < this.GetAdminValidations[i].validations.length; j++) {
					if (this.GetAdminValidations[i].validations[j].id == id) {
						this.deleteValidationGql(parseInt(this.GetAdminValidations[i].id), this.GetAdminValidations[i].validations[j].department);
						this.GetAdminValidations[i].validations.splice(j, 1); // remove element
						return;
					}
				}
			}
		}
	},
	computed: {
		optionsForValidationLevel() {
			return this.FormMenu(1013);
		},
		availableDepartments: function () {
			var departmentNames = [];
			var ret = [];
			//console.log("this.GetDepartmentsForValidation:",this.GetDepartmentsForValidation);
			if (Object.keys(this.GetAdminValidations).length === 0 || Object.keys(this.GetDepartmentsForValidation).length === 0) {
				return null;
			}

			for (var i = 0; i < this.GetAdminValidations.length; i++) {
				if (this.GetAdminValidations[i].id == this.currentUserID) {
					for (var l = 0; l < this.GetAdminValidations[i].validations.length; l++) {
						departmentNames.push(this.GetAdminValidations[i].validations[l].departmentName);
					}
					break;
				}
			}

			for (var j = 0; j < this.GetDepartmentsForValidation.length; j++) {
				var isAlreadyUsed = false;
				for (var k = 0; k < departmentNames.length; k++) {
					if (this.GetDepartmentsForValidation[j].message == departmentNames[k]) {
						isAlreadyUsed = true;
						break;
					}
				}
				if (!isAlreadyUsed) {
					ret.push(this.GetDepartmentsForValidation[j]);
				}
			}

			return ret;
		}
	}
};
</script>

<style>
.movable {
	cursor: move;
}
.ghost {
	background-color: grey;
}
.draggable {
	margin-left: 10px;
}
.drophere {
	padding: 10px;
	/* background-color: rgba(249, 249, 249, 1); */
	/* border: 1px dashed rgb(206, 206, 206); */
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
</style>
